import { disableScroll } from './disable-scroll';
import { enableScroll } from './enable-scroll';

(function(){
  const burger = document?.querySelector('[data-burger]');
  const menu = document?.querySelector('[data-menu]');
  const headerActions = document?.querySelector('.header__actions');
  const body = document.body;

  burger?.addEventListener('click', (e) => {
    burger?.classList.toggle('burger--active');
    menu?.classList.toggle('header__nav--active');
    body?.classList.toggle('nav-active');
    headerActions?.classList.toggle('header__actions--nav-active');

    if (menu?.classList.contains('header__nav--active')) {
      disableScroll()
    } else {
      enableScroll()
    }

  });
})();
