const header = document?.querySelector('.header');

import { burger } from './functions/burger';
import { accordion } from './functions/accordion';
import { select } from './functions/select';

const services = document?.querySelectorAll('.services-dropdown .select-items > div');

for (let service of services) {
  service.addEventListener('click', () => {
    setAreaImg()
  })
}
setAreaImg()

function setAreaImg() {
  const servicesSelect = document?.querySelector('#services-select');
  const areaImg = document?.querySelector('#area-img');
  if (areaImg) { areaImg.src = servicesSelect.value };
}

// Получение высоты шапки сайта
import { getHeaderHeight } from './functions/header-height';
window.addEventListener('resize', (e) => {
  getHeaderHeight();
})
getHeaderHeight();

const scrollDownBtn = document?.querySelector('[data-scroll-down]');

scrollDownBtn && scrollDownBtn?.addEventListener('click', (e) => {
  e.preventDefault();
  const hero = document?.querySelector('.hero');
  window.scroll({
    top: hero.offsetHeight - header.offsetHeight,
    behavior: 'smooth'
  });
})

 // Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// function youtube_parser(url){
//   var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
//   var match = url.match(regExp);
//   return (match&&match[7].length==11)? match[7] : false;
// }
// const reviewIframe = document?.querySelector('.video-review__iframe');

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal({
//   isClose: (modal) => {
//     reviewIframe?.removeAttribute('src');
// 	},
// });

// document?.querySelector('.open-review-modal')?.addEventListener('click', (e) => {
//   e.preventDefault();
//   let src = youtube_parser(e.target.href);
//   reviewIframe?.setAttribute('src', `https://www.youtube.com/embed/${src}`)
// 	new GraphModal().open('video-review');
// });

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Подключение плагина кастом-скролла
// import 'simplebar';
