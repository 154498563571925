import Swiper, {Mousewheel, Pagination} from 'swiper';

let mql = window.matchMedia('(min-width: 992px)');

window.addEventListener('resize', () => {
  initFullpageSlider();
})
initFullpageSlider();

function initFullpageSlider() {
  let fullPageSlider;
  if (mql.matches) {

    fullPageSlider = new Swiper('.fullpage-slider', {
      modules: [Mousewheel, Pagination],
      slidesPerView: 1,
      loop: false,
      speed: 400,
      spaceBetween: 0,
      direction: "vertical",
      allowTouchMove: false,
      mousewheel: {

      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        320: {

        },
        480: {

        },
        640: {

        }
      }
    });
  }

  if (!mql.matches && fullPageSlider) {
    fullPageSlider.destroy(true, true);
  }
}
